import React, { useContext } from "react"
import Query from "../components/Query"
import { AppContext } from "../contexts/AppContext"
import NotAuthorized from "../components/NotAuthorized"
import ContentUserStation from "../components/ContentUserStation"

import { QUERY_STATION_BY_ID_BASIC_DETAILS } from "../queries/page/station"

const UserStation = () => {
  const { userState, countries } = useContext(AppContext)
  // use fetchPolicy = 'network-only' to force fetch
  return (
    <React.Fragment>
      {userState.stationID ? (
        <Query
          query={QUERY_STATION_BY_ID_BASIC_DETAILS}
          variables={{
            id: userState.stationID,
          }}
          fetchPolicy="network-only"
        >
          {({ data, refetch }) => {
            return (
              <React.Fragment>
                <ContentUserStation
                  refetch={refetch}
                  data={data}
                  countries={countries}
                />
              </React.Fragment>
            )
          }}
        </Query>
      ) : (
        <NotAuthorized>
          You are not authorized to see contents of this page
        </NotAuthorized>
      )}
    </React.Fragment>
  )

  /*
  
  */
}

export default UserStation
