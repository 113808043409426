import React, { useContext, useState } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import * as St from "../Styled"
import { NotificationManager } from "react-notifications"
import { AppContext } from "../../contexts/AppContext"
import { useMutation } from "@apollo/client"
import {
  MUTATION_UPDATE_STATION,
  MUTATION_ADD_BROADCASTFREQS,
  MUTATION_DELETE_BROADCASTFREQS,
} from "../../queries/page/station"
import objectPath from "object-path"
import axios from "axios"
import auth from "../../auth/auth"

import { awsStationImagesPath, COUNTRY_ALL } from "../../constants/props"
import useButtonLoader from "../../hooks/useButtonLoader"
import ProgressContent from "../ProgressBar"

const StationUpdateForm = props => {
  let { device, userState } = useContext(AppContext)
  const { handleSubmit, register, errors, control } = useForm({
    defaultValues:
      props.data && props.data.station ? props.data.station : undefined,
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: "broadcastfreqs",
  })

  const [addBroadcastFreqsMutation] = useMutation(MUTATION_ADD_BROADCASTFREQS)
  const [deleteBroadcastFreqsMutation] = useMutation(
    MUTATION_DELETE_BROADCASTFREQS
  )

  const [updateStation] = useMutation(MUTATION_UPDATE_STATION)
  const [coverImage, setCoverImage] = useState({ preview: "", raw: "" })
  const [progressOfImage, setProgressOfImage] = useState({
    count: 0,
    title: "",
  })
  const handleChange = e => {
    if (e.target.files.length) {
      setCoverImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }

  const stationData =
    props.data && props.data.station ? props.data.station : undefined

  const [loaderElement, setLoader] = useButtonLoader(
    "Update Station",
    "Processing..."
  )

  const basePrefix = `${process.env.STRAPI_SERVER_URL}` // SERVER BASE

  // Add frequencies against station
  const addBroadcastFreqs = async frequencies => {
    if (frequencies && frequencies.length) {
      for (let frequency of frequencies) {
        await addBroadcastFreqsMutation({
          variables: {
            input: {
              data: {
                origin: frequency.origin,
                frequency: frequency.frequency,
                station: userState.station ? userState.station.id : null,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        }).then(() => {})
      }
    }
  }

  const deleteBroadcastFreqs = async broadcastFreqIDs => {
    if (broadcastFreqIDs && broadcastFreqIDs.length) {
      for (let freqID of broadcastFreqIDs) {
        await deleteBroadcastFreqsMutation({
          variables: {
            input: {
              where: {
                id: freqID,
              },
            },
          },
          context: {
            headers: {
              Authorization: "Bearer " + auth.getToken(),
            },
          },
        })
          .then(() => {
            return true
          })
          .catch(() => {
            return true
          })
      }
    }
  }

  const onSubmit = values => {
    setLoader(true)
    // First upload audio file and then create a mediacontent entry with uploaded url and logged in user
    updateStation({
      variables: {
        input: {
          data: {
            title: values.title,
            contact: values.contact,
            location: values.location,
            about: values.about,
            country:
              values.country && values.country.id
                ? values.country.id
                : undefined,
          },
          where: {
            id: userState.stationID,
          },
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
      .then(response => {
        if (
          props.data.station.broadcastfreqs &&
          props.data.station.broadcastfreqs.length
        ) {
          deleteBroadcastFreqs(
            props.data.station.broadcastfreqs.map(e => e.id)
          ).then(() => {
            if (values.broadcastfreqs && values.broadcastfreqs.length) {
              addBroadcastFreqs(values.broadcastfreqs).then(() => {
                if (coverImage.raw) {
                  const coverFormData = new FormData()
                  coverFormData.append("files", coverImage.raw)
                  coverFormData.append("ref", "station")
                  coverFormData.append("refId", userState.stationID)
                  coverFormData.append("field", "image")
                  coverFormData.append(
                    "path",
                    awsStationImagesPath(values.title)
                  )
                  axios
                    .post(`${basePrefix}/upload`, coverFormData, {
                      headers: {
                        Authorization: "Bearer " + auth.getToken(),
                      },
                      onUploadProgress: function(progressEvent) {
                        var percentCompleted = Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                        )
                        setProgressOfImage({
                          count: percentCompleted - 0.05 * percentCompleted,
                          title: "Uploading " + coverImage.name,
                        })
                      },
                    })
                    .then(function() {
                      NotificationManager.success("Station updated...")
                      setProgressOfImage({
                        count: 100,
                        title: "Image Successfully Uploaded",
                      })
                      // trigger refetch of station data so that form is updated accordingly
                      props.refetch()
                      setLoader(false)
                    })
                } else {
                  NotificationManager.success("Station updated...")
                  // trigger refetch of station data so that form is updated accordingly
                  props.refetch()
                  setLoader(false)
                }
              })
            } else {
              if (coverImage.raw) {
                const coverFormData = new FormData()
                coverFormData.append("files", coverImage.raw)
                coverFormData.append("ref", "station")
                coverFormData.append("refId", userState.stationID)
                coverFormData.append("field", "image")
                coverFormData.append(
                  "path",
                  awsStationImagesPath(values.title)
                )
                axios
                  .post(`${basePrefix}/upload`, coverFormData, {
                    headers: {
                      Authorization: "Bearer " + auth.getToken(),
                    },
                    onUploadProgress: function(progressEvent) {
                      var percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                      )
                      setProgressOfImage({
                        count: percentCompleted - 0.05 * percentCompleted,
                        title: "Uploading " + coverImage.name,
                      })
                    },
                  })
                  .then(function() {
                    NotificationManager.success("Station updated...")
                    setProgressOfImage({
                      count: 100,
                      title: "Image Successfully Uploaded",
                    })
                    // trigger refetch of station data so that form is updated accordingly
                    props.refetch()
                    setLoader(false)
                  })
              } else {
                NotificationManager.success("Station updated...")
                // trigger refetch of station data so that form is updated accordingly
                props.refetch()
                setLoader(false)
              }
            }
          })
        } else if (values.broadcastfreqs && values.broadcastfreqs.length) {
          addBroadcastFreqs(values.broadcastfreqs).then(() => {
            if (coverImage.raw) {
              const coverFormData = new FormData()
              coverFormData.append("files", coverImage.raw)
              coverFormData.append("ref", "station")
              coverFormData.append("refId", userState.stationID)
              coverFormData.append("field", "image")
              coverFormData.append("path", awsStationImagesPath(values.title))
              axios
                .post(`${basePrefix}/upload`, coverFormData, {
                  headers: {
                    Authorization: "Bearer " + auth.getToken(),
                  },
                  onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )
                    setProgressOfImage({
                      count: percentCompleted - 0.05 * percentCompleted,
                      title: "Uploading " + coverImage.name,
                    })
                  },
                })
                .then(function() {
                  NotificationManager.success("Station updated...")
                  setProgressOfImage({
                    count: 100,
                    title: "Image Successfully Uploaded",
                  })
                  // trigger refetch of station data so that form is updated accordingly
                  props.refetch()
                  setLoader(false)
                })
            } else {
              NotificationManager.success("Station updated...")
              // trigger refetch of station data so that form is updated accordingly
              props.refetch()
              setLoader(false)
            }
          })
        } else {
          // no broadcast frequencies provided
          if (coverImage.raw) {
            const coverFormData = new FormData()
            coverFormData.append("files", coverImage.raw)
            coverFormData.append("ref", "station")
            coverFormData.append("refId", userState.stationID)
            coverFormData.append("field", "image")
            coverFormData.append(
              "path",
              awsStationImagesPath(values.title)
            )
            axios
              .post(`${basePrefix}/upload`, coverFormData, {
                headers: {
                  Authorization: "Bearer " + auth.getToken(),
                },
                onUploadProgress: function(progressEvent) {
                  var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  )
                  setProgressOfImage({
                    count: percentCompleted - 0.05 * percentCompleted,
                    title: "Uploading " + coverImage.name,
                  })
                },
              })
              .then(function() {
                NotificationManager.success("Station updated...")
                setProgressOfImage({
                  count: 100,
                  title: "Image Successfully Uploaded",
                })
                // trigger refetch of station data so that form is updated accordingly
                props.refetch()
                setLoader(false)
              })
          } else {
            NotificationManager.success("Station updated...")
            // trigger refetch of station data so that form is updated accordingly
            props.refetch()
            setLoader(false)
          }
        }
      })
      .catch(() => {
        NotificationManager.error("Station Update failed...", false)
        // trigger refetch of station data so that form is updated accordingly
        props.refetch()
        setLoader(false)
      })
  }

  let coverImageURL =
    stationData && stationData.image && stationData.image.url
      ? !stationData.image.url.startsWith("http")
        ? basePrefix + stationData.image.url
        : stationData.image.url
      : undefined

  return (
    <St.Form
      ismobile={device === "mobile"}
      onSubmit={handleSubmit(onSubmit)}
      enctype="multipart/form-data"
    >
      <div>
        <St.Label>Title</St.Label>
        <St.FormInput
          name="title"
          aria-invalid={errors.title ? "true" : "false"}
          aria-describedby="stationTitleError"
          ref={register({
            required: "Please add title of this station",
          })}
        />
        {errors.title && <St.ErrorText>{errors.title.message}</St.ErrorText>}
      </div>
      <div>
        <St.Label>Contact Number</St.Label>
        <St.FormInput
          name="contact"
          aria-invalid={errors.contact ? "true" : "false"}
          aria-describedby="contactError"
          ref={register({
            required: "Please add contact person name",
          })}
        />
        {errors.contact && (
          <St.ErrorText>{errors.contact.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Cover Image (Recommended Size: 250 X 250 pixels)</St.Label>
        <St.FileTypeLabel htmlFor="upload-cover">
          {coverImage.preview ? (
            <St.Image src={coverImage.preview} alt="dummy"></St.Image>
          ) : coverImageURL ? (
            <St.Image src={coverImageURL} alt="dummy"></St.Image>
          ) : (
            <>Click here to Upload Cover Image</>
          )}
        </St.FileTypeLabel>
        <St.FormInputFile
          id="upload-cover"
          name="coverImage"
          aria-invalid={errors.coverImage ? "true" : "false"}
          aria-describedby="coverImageError"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleChange}
          ref={
            !coverImageURL
              ? register({
                  required: "Please select cover image for this Station",
                })
              : undefined
          }
        />

        

        {errors.coverImage && (
          <St.ErrorText>{errors.coverImage.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Location</St.Label>
        <St.FormInput
          name="location"
          aria-invalid={errors.location ? "true" : "false"}
          aria-describedby="locationError"
          ref={register({
            required: "Please add place where this station is located",
          })}
        />

        {errors.location && (
          <St.ErrorText>{errors.location.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Country</St.Label>
        <St.FormSelect
          name="country.id"
          aria-invalid={errors.country ? "true" : "false"}
          aria-describedby="countryError"
          ref={register({
            required: "Please select country of this station",
          })}
        >
          <option value="">-- Select Country --</option>
          {props.countries &&
            props.countries.map((country, i) => (
              country.name != COUNTRY_ALL && <option
                selected={
                  (stationData.country && stationData.country.id) === country.id
                }
                key={i}
                value={country.id}
              >
                {country.name}
              </option>
            ))}
        </St.FormSelect>
        {errors.country && errors.country.id && (
          <St.ErrorText>{errors.country.id.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>About</St.Label>
        <St.FormTextarea
          name="about"
          aria-invalid={errors.about ? "true" : "false"}
          aria-describedby="aboutStationError"
          ref={register({
            required: "Please add something about this station",
          })}
        />
        {errors.about && <St.ErrorText>{errors.about.message}</St.ErrorText>}
      </div>
      {fields.map((item, index) => (
        <St.FieldArraySection key={item.id}>
          <div>
            <St.FieldArrayLabel>City</St.FieldArrayLabel>
            <St.FormInput
              name={`broadcastfreqs[${index}].origin`}
              ref={register({
                required: "Please add city for adding frequency",
              })}
            />
            {objectPath.get(errors, `broadcastfreqs.${index}.origin`) && (
              <St.ErrorText>
                {
                  objectPath.get(errors, `broadcastfreqs.${index}.origin`)
                    .message
                }
              </St.ErrorText>
            )}
          </div>
          <div>
            <St.FieldArrayLabel>Freqency</St.FieldArrayLabel>
            <St.FormInput
              name={`broadcastfreqs[${index}].frequency`}
              ref={register({
                required: "Please add frequency for this city",
              })}
            />
            {objectPath.get(errors, `broadcastfreqs.${index}.frequency`) && (
              <St.ErrorText>
                {
                  objectPath.get(errors, `broadcastfreqs.${index}.frequency`)
                    .message
                }
              </St.ErrorText>
            )}
          </div>
          <St.FieldArrayDeleteButtonContainer>
            <button type="button" onClick={() => remove(index)}>
              Delete
            </button>
          </St.FieldArrayDeleteButtonContainer>
        </St.FieldArraySection>
      ))}
      <St.FieldArrayAddButtonContainer>
        <button type="button" onClick={() => append({})}>
          Add Frequency
        </button>
      </St.FieldArrayAddButtonContainer>

      <St.SubmitButton ref={loaderElement}>Update Station</St.SubmitButton>
      {progressOfImage.count < 100 && (
        <ProgressContent
          progress={progressOfImage.count}
          title={progressOfImage.title}
        />
      )}
    </St.Form>
  )
}

const ContentUserStation = props => {
  let { device } = useContext(AppContext)
  return (
    <St.ContentWrapper>
      <St.ContentSection>
        <St.LeftAdSection />

        <St.CenterContentWrapper>
          <St.DataTopHeadingWrapper style={{ justifyContent: "center" }}>
            <St.DataTopHeading ismobile={device === "mobile"}>
              Station Details
            </St.DataTopHeading>
          </St.DataTopHeadingWrapper>

          <St.CenterContent>
            <StationUpdateForm
              device={device}
              data={props.data}
              countries={props.countries}
              refetch={props.refetch}
            />
          </St.CenterContent>
          <St.BottomAdSection />
        </St.CenterContentWrapper>
        <St.RightAdSection />
      </St.ContentSection>
    </St.ContentWrapper>
  )
}

export default ContentUserStation
